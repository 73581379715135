import * as React from 'react';
import PropTypes from 'prop-types';
import OfferAgreement from '../../component/OfferAgreement';

export default function OfferAgreementPage({ text }) {
  return <OfferAgreement text={text} />;
}

OfferAgreementPage.propTypes = {
  text: PropTypes.string.isRequired,
};
