import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../component/layout';
import Seo from '../component/seo';
import OfferAgreementPage from '../sections/OfferAgreementpage';

export default function OfferAgreement() {
  const {
    strapiMetaPage: { header, description },
    strapiOfferAgreement: {
      header: titleHeader,
      text: {
        data: { text },
      },
    },
  } = useStaticQuery(
    graphql`
      query OfferAgreement {
        strapiMetaPage(page_name: { eq: "offer-agreement" }) {
          header
          description
          page_name
        }
        strapiOfferAgreement {
          header
          text {
            data {
              text
            }
          }
        }
      }
    `,
  );

  return (
    <Layout titleHeader={titleHeader} activePath="/offer-agreement">
      <Seo title={header} description={description} />
      <OfferAgreementPage text={text} />
    </Layout>
  );
}
